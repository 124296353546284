html {
  background-color: #d4d2db;
  color: #11074c;
}
a {
  color: #11074c;
}
textarea:focus,
input:focus {
  outline: none;
}
li:hover {
  border-radius: 5px;
}
.li:hover {
  background-color: initial;
}
.scrollbar::-webkit-scrollbar {
  display: none; /* For Chrome, Safari and Opera */
}
.scrollbar {
  -ms-overflow-style: none;
}
.textLayer {
  height: 0 !important;
  display: none;
}
.annotationLayer {
  height: 0 !important;
  display: none;
}
.bi-x-circle-fill::before {
  background-color: black;
  border-radius: 60%;
}
/* .cont {
  border-top: 40px solid #ff5757;
  height: auto;
  padding: 10px;
  background-color: rgb(205 213 220 / 64%);
} */
label {
  font-weight: bold;
  margin: 5px;
}
.table {
  --bs-table-bg: #adceeca3;
  border-radius: 20px;
}
.Deskcontainer {
  margin-top: 3rem;
  margin-bottom: 4rem;
  margin-left: 0;
  padding: 0;
  color: #11074c;
  background-color: #d4d2db;
}
.f-cont {
  width: 90%;
  margin: 0 auto;
  margin-bottom: 100px;
  border-radius: 20px;
  border: 2px solid #11074c;
  background-color: #fff;
}

/* .TrendingMenu css */
.TrendingMenu {
  padding: 0;
}
.MenuRow {
  border-bottom: 1px solid #11074c;
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.MenuRow h1 {
  width: 20%;
  text-align: center;
}
.item-details {
  width: 40%;
}
.item-img {
  width: 30%;
}

h1 {
  font-weight: bold;
}
.modal-backdrop {
  z-index: 0;
}
/* .form-control{
    border: none;
    box-shadow: none;
    outline: none;
    padding: 5px;
    border-bottom: 2px solid #11074C;
} */
.nav-link:hover,
.nav-link:active,
.nav-link:focus {
  color: #11074c;
}
.sub-btn {
  background-color: #11074c;
  color: white;
  width: 100%;
  border-radius: 20px;
  border: none;
  padding: 6px;
}
.change-btn:hover {
  opacity: 0.5 !important;
}

.OrderBox {
  flex-direction: column;
}
.order-card {
  width: 100%;
}
.edit {
  background-color: rgb(255, 255, 255, 0.5);
  position: absolute;
  top: 0;
  left: 30%;
  font-size: 50px;
  opacity: 0;
  margin: auto;
  width: 40%;
  height: 100%;
  border-radius: 20px;
}
.edit:hover {
  opacity: 1;
}
.edit input[type="file"] {
  width: 95%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
  position: absolute;
}
.orderNav {
  list-style: none;
  display: flex;
  margin: auto;
  width: fit-content;
}
.orderNav .nav-item {
  border-radius: 50px;
  margin: 10px;
}
.orderNav .nav-item .orderNav-link {
  padding: 10px;
}
.orderNav .nav-item .active {
  border-radius: 50px;
  background-color: white;
}
.cart-span {
  display: none;
}
.policy::after {
  display: none;
}
@media screen and (min-width: 480px) {
  .header {
    z-index: 134;
  }
  .OrderBox {
    flex-direction: row;
  }
  .order-card {
    width: 30%;
  }
  .footer {
    position: fixed;
    left: 0;
    width: fit-content !important;
    background-color: whitesmoke !important;
    height: 100%;
    border-right: #11074c solid 1px;
  }
  .bi-circle-fill {
    display: none;
  }
  .bi-cart3 {
    position: relative !important;
    bottom: 0 !important;
  }
  .cart-span {
    display: block;
  }
  .cart-card {
    width: 35%;
  }
  .nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    flex-direction: column;
    align-items: center;
    align-content: center;
  }
  .nav-link .bi {
    font-size: 35px !important;
  }
  .nav-link {
    font-size: 15px !important;
  }
  .nav-item {
    margin: 20px;
    width: 80%;
  }
  .store-card {
    width: 30% !important;
  }
  .menu {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .storecards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .nav-item .active {
    border-radius: 10px;
    background-color: #cccbc8;
  }
  .Deskcontainer {
    margin-top: 2.5rem;
    margin-left: 9.5rem;
    padding: 1.5rem;
  }
  .f-cont {
    margin: auto;
    width: 40%;
  }
  .storeImg {
    width: 40% !important;
  }
  .headerTitle {
    left: 10% !important;
  }
  .profile,
  .loginBtn,
  .logoutBtn,
  .opt {
    width: 40%;
    margin: 1rem auto !important;
  }
  .orderNav {
    width: 25%;
  }
  .noti-accordion {
    width: 50%;
    margin: 3rem auto !important;
  }
  .details {
    width: 40%;
    margin: auto !important;
  }
  .orders {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; 
  }
  .policy-back {
    margin-top:0 !important;
  }
}
.policy-back {
  margin-top: 3.5rem;
}

.link-cont {
  font-size: 15px;
}
.or {
  display: inline-flex;
}
.or hr {
  margin: 10px;
  width: 100px !important;
  border-top: 2px solid #11074c;
}

.footer {
  z-index: 100;
  position: fixed;
  margin-top: 200px;
  bottom: 0;
  left: 0;
  background-color: #fff;
  width: 100%;
}
.nav-link {
  color: #11074c;
  font-weight: 900;
  font-size: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.nav-link .bi {
  font-size: 20px;
}
.nav-link .c1 {
  font-size: 60px;
  color: white;
  position: fixed;
  bottom: 0;
}
.nav-link .c2 {
  font-size: 50px;
  color: #fbad02;
  position: fixed;
  bottom: 8px;
}
.nav-link .c3 {
  font-size: 35px;
  /* color:  #11074C; */
  position: fixed;
  bottom: 20px;
}
.back-btn {
  padding: 2px 10px;
  cursor: pointer;
  text-align: left;
  background-color: white;
  border-radius: 20px;
}
.add-btn {
  width: 90px !important;
}
.btn {
  z-index: 0;
}
.btn:focus {
  outline: 0;
  border: none;
  box-shadow: 0 0 0 0px !important;
}

/* Messages CSS */

.inc-msg p {
  border-top-left-radius: 0;
  text-align: left;
  margin: 0.5rem;
}
.inc-msg p:first-child::after {
  content: "";
  position: absolute;
  top: 0;
  right: 100%;
  border-top: 0px solid transparent;
  border-right: 10px solid rgb(205 213 220 / 64%);
  border-bottom: 12px solid transparent;
}
.out-msg p {
  border-top-left-radius: 0;
  text-align: right;
  margin: 0.5rem;
  display: inline;
}
.out-msg p:first-child::after {
  content: "";
  position: absolute;
  top: 0;
  left: 100%;
  border-top: 0px solid transparent;
  border-left: 10px solid #ee817f7a;
  border-bottom: 12px solid transparent;
}
.ms-chat-status::after {
  content: "";
  position: absolute;
  bottom: 0;
  right: 0;
  width: 10px;
  height: 10px;
  border: 1px solid #fff;
  border-radius: 50%;
}
.ms-panel {
  background-color: white;
}

.new-msg .msg-count {
  position: absolute;
  top: -2px;
  left: -2px;
  width: 17px;
  height: 17px;
  border: 1px solid #fff;
  background: #ff0018;
  border-radius: 50%;
  font-size: 10px;
  text-align: center;
  color: #11074c;
}

li:hover {
  background-color: #e9ecef;
}

::-webkit-scrollbar {
  width: 2px;
}
/* Track */
::-webkit-scrollbar-track {
  background: rgb(241, 241, 241, 50%);
  border-radius: 10px;
  float: none;
  z-index: 233;
  display: block;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(136, 136, 136, 50%);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(85, 85, 85, 50%);
  border-radius: 10px;
}
/* .ps__rail-x {
  display: none;
  opacity: 0;
  transition: background-color .2s linear, opacity .2s linear;
  -webkit-transition: background-color .2s linear, opacity .2s linear;
  height: 15px;
  bottom: 0px;
  position: absolute;
}
.ps__rail-y {
  display: none;
  opacity: 0;
  transition: background-color .2s linear, opacity .2s linear;
  -webkit-transition: background-color .2s linear, opacity .2s linear;
  width: 6px;
  right: 0;
  position: absolute;
}
.ps__thumb-y {
  background-color: #aaa;
  border-radius: 6px;
  transition: background-color .2s linear, width .2s ease-in-out;
  -webkit-transition: background-color .2s linear, width .2s ease-in-out;
  width: 6px;
  right: 0;
  position: absolute;
} */

@keyframes loading-1 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(36deg);
    transform: rotate(36deg);
  }
}
@keyframes loading-2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(72deg);
    transform: rotate(72deg);
  }
}
@keyframes loading-3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(108deg);
    transform: rotate(108deg);
  }
}
@keyframes loading-4 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(144deg);
    transform: rotate(144deg);
  }
}
@keyframes loading-5 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}
